.thumb-img {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 250px;
  width: 100%;

  &.product-image {
    height: 250px;
    width: auto;
    max-width: 250px;
  }
}

.thumb-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
